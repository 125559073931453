import { Component, ViewChild, Input, forwardRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'jhi-typeahead',
  templateUrl: './typeahead-scrollable.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JhiTypeaheadScrollableComponent),
      multi: true
    }
  ],
  styleUrls: ['./typeahead-scrollable.component.scss']
})
export class JhiTypeaheadScrollableComponent implements ControlValueAccessor {
  @Input() public dataValue: string;
  @Input() public dataText: string;
  @Input() public disable = false;
  @Input() public clearButton = false;
  @Output() public change = new EventEmitter();
  @ViewChild('typeaheadInstance') private typeaheadInstance: NgbTypeahead;
  @ViewChild('typeaheadInput') private typeaheadInput: ElementRef;
  @Input() _typeaheadValue = '';
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  _dataSource: any[];
  @Input()
  set dataSource(value: any[]) {
    this._dataSource = value;
    this.setTextValue();
  }
  get dataSource(): any[] {
    return this._dataSource;
  }
  get typeaheadValue() {
    return this._typeaheadValue;
  }

  set typeaheadValue(val) {
    this._typeaheadValue = val;
    this.propagateChange(this._typeaheadValue);
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.typeaheadValue = obj;
    }
    this.setTextValue();
  }

  setTextValue(): void {
    this.typeaheadInput.nativeElement.value = null;
    if (this.dataSource && this._dataSource.length > 0 && this.typeaheadValue) {
      const item = this.dataSource.find(value => {
        return this.formatItem(this.dataValue, value) === this.typeaheadValue;
      });
      if (item) {
        this.typeaheadInput.nativeElement.value = this.formatItem(this.dataText, item);
      }
    }
  }

  onChangeInputValue(): void {
    if (!this.typeaheadInput.nativeElement.value) {
      this.typeaheadValue = null;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  formatter = (result: any) => {
    return this.formatItem(this.dataText, result);
  };
  formatItem = (expression: string, result: any) => {
    if (expression && result) {
      const evalfunc = new Function(...Object.keys(result), 'return ' + expression);
      return evalfunc(...Object.values(result));
    }
    return result;
  };

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.typeaheadInstance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term =>
        term.length < 2
          ? this.dataSource
          : this.dataSource.filter(
              v =>
                this.formatItem(this.dataText, v)
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1
            )
      )
    );
  };

  selectedItem(event) {
    this.typeaheadValue = this.formatItem(this.dataValue, event.item);
    this.change.emit();
  }

  typeaheadKeydown($event: KeyboardEvent) {
    if (this.typeaheadInstance.isPopupOpen()) {
      setTimeout(() => {
        const popup = document.getElementById(this.typeaheadInstance.popupId);
        const activeElements = popup.getElementsByClassName('active');
        if (activeElements.length === 1) {
          // activeElements[0].scrollIntoView();
          const elem = activeElements[0] as any;
          if (typeof elem.scrollIntoViewIfNeeded === 'function') {
            // non standard function, but works (in chrome)...
            elem.scrollIntoViewIfNeeded();
          } else {
            // do custom scroll calculation or use jQuery Plugin or ...
            this.scrollIntoViewIfNeededPolyfill(elem as HTMLElement);
          }
        }
      });
    }
  }

  /**
   * ... use https://gist.github.com/hsablonniere/2581101
   */
  private scrollIntoViewIfNeededPolyfill(elem: HTMLElement, centerIfNeeded = true) {
    const parent = elem.parentElement,
      parentComputedStyle = window.getComputedStyle(parent, null),
      parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'), 10),
      parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'), 10),
      overTop = elem.offsetTop - parent.offsetTop < parent.scrollTop,
      overBottom = elem.offsetTop - parent.offsetTop + elem.clientHeight - parentBorderTopWidth > parent.scrollTop + parent.clientHeight,
      overLeft = elem.offsetLeft - parent.offsetLeft < parent.scrollLeft,
      overRight = elem.offsetLeft - parent.offsetLeft + elem.clientWidth - parentBorderLeftWidth > parent.scrollLeft + parent.clientWidth,
      alignWithTop = overTop && !overBottom;

    if ((overTop || overBottom) && centerIfNeeded) {
      parent.scrollTop = elem.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + elem.clientHeight / 2;
    }

    if ((overLeft || overRight) && centerIfNeeded) {
      parent.scrollLeft = elem.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + elem.clientWidth / 2;
    }

    if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
      elem.scrollIntoView(alignWithTop);
    }
  }

  clear() {
    this.typeaheadInput.nativeElement.value = null;
    this._typeaheadValue = null;
    this.typeaheadValue = null;
    this.change.emit();
  }
}
