import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { Item } from 'app/core/bin-content/item.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public resourceUrl = SERVER_API_URL + 'api/shared/';
  public years: string[];
  public items: string[];
  private itemsWithParents: Item[];
  private itemsAndCategories: string[];

  constructor(private http: HttpClient) {}

  fetchYears(): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(this.resourceUrl + 'years', { observe: 'response' });
  }

  getYears(): Promise<string[]> {
    if (this.years) {
      return Promise.resolve(this.years);
    }
    return this.fetchYears()
      .toPromise()
      .then(response => {
        this.years = response.body;
        this.sortYears();
        return this.years;
      })
      .catch(err => {
        return null;
      });
  }
  sortYears() {
    this.years.sort((a, b) => (a < b ? 1 : a === b ? 0 : -1));
  }

  fetchItems(offBalance?): Observable<HttpResponse<Item[]>> {
    const param = {};
    if (offBalance) {
      param['offBalance'] = true;
    }
    return this.http.get<Item[]>(this.resourceUrl + 'items', { params: param, observe: 'response' });
  }

  getItemsAndCategories(): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(this.resourceUrl + 'itemcategories', { observe: 'response' });
  }
  getItemsAndCategoriesPromise(): Promise<string[]> {
    if (this.itemsAndCategories) {
      return Promise.resolve(this.itemsAndCategories);
    }
    return this.getItemsAndCategories()
      .toPromise()
      .then(response => {
        this.itemsAndCategories = response.body;
        return this.itemsAndCategories;
      })
      .catch(err => {
        return null;
      });
  }

  getItemsWithParents(): Promise<Item[]> {
    if (this.itemsWithParents) {
      return Promise.resolve(this.itemsWithParents);
    }
    return this.fetchItems()
      .toPromise()
      .then(response => {
        this.itemsWithParents = response.body;
        return this.itemsWithParents;
      })
      .catch(err => {
        return null;
      });
  }

  getItems(): Promise<string[]> {
    if (this.items) {
      return Promise.resolve(this.items);
    }
    return this.fetchItems()
      .toPromise()
      .then(response => {
        this.items = response.body.map(el => {
          return el.itemNo;
        });
        return this.items;
      })
      .catch(err => {
        return null;
      });
  }

  getPDF(pdfUrl): Observable<Blob> {
    return this.http.get(this.resourceUrl + 'download', {
      params: { url: pdfUrl },
      responseType: 'blob'
    });
  }
  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  round(number, decimalPlaces) {
    return Math.round((number + Number.EPSILON) * 10 ** decimalPlaces) / 10 ** decimalPlaces;
  }

  formatNumber(number) {
    const rounded = this.round(number, 2);
    const parts = rounded.toString().split('.');
    const result = [];
    result.push(parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    if (parts.length < 2) {
      result.push('');
    } else {
      result.push('.' + parts[1]);
    }
    return result;
  }

  formatNumberResultString(number) {
    const formatList = this.formatNumber(number);
    return formatList.join('');
  }
}
